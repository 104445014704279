import { type SxProps } from '@mui/material'
import { borderRadius, spacing } from '@orus.eu/pharaoh'

export const navMenuItemSx: SxProps = {
  borderRadius: borderRadius[20],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: spacing[50],
  padding: `${spacing[30]} ${spacing[50]} ${spacing[30]} ${spacing[50]}`,
}

export const navSecondaryLinkSx: SxProps = {
  textDecoration: 'none',
  justifyContent: 'flex-start',
  padding: `${spacing[30]} ${spacing[50]} ${spacing[30]} ${spacing[50]}`,
}
