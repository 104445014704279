import { memo } from 'react'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { GenericProblemMessage } from '../../../organisms/generic-problem-message'

export const NotConnectedProblemMessage = memo<{ redirect: string }>(function NotConnectedProblemMessage({ redirect }) {
  const goToLogin = useNavigateTo({
    to: '/login',
    search: { redirect },
  })
  return (
    <GenericProblemMessage
      title="Connectez vous"
      principalMessage="Vous devez être connecté pour voir cette page."
      firstSubText="Cliquez sur le bouton ci dessous pour aller à la page de login."
      buttonText="Me connecter"
      onButtonClick={goToLogin}
    />
  )
})
