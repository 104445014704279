import { useSession } from '../../../lib/session'
import { Page } from '../page'
import { InsufficientRightsProblemMessage } from './components/InsufficientRightsProblemMessage'
import { NotConnectedProblemMessage } from './components/NotConnectedProblemMessage'

export function InviteToLoginPage(): JSX.Element {
  const session = useSession()

  return (
    <Page>
      {session.user ? (
        <InsufficientRightsProblemMessage user={session.user} />
      ) : (
        <NotConnectedProblemMessage redirect={window.location.href.substring(window.location.origin.length)} />
      )}
    </Page>
  )
}
