import { Box, Button, type SxProps } from '@mui/material'
import {
  colors,
  Icon,
  PIXELS_PER_ICON_SIZE,
  secondaryColor,
  spacing,
  Text,
  type CompoundIconName,
  type IconSize,
} from '@orus.eu/pharaoh'
import { createLink } from '@tanstack/react-router'
import { useLargeScreen } from '../../lib/use-large-screen'
import { navMenuItemSx } from './nav-menu-item-style'

type NavMenuItemVariant = 'backoffice' | 'clientapp'

export type NavMenuItemProps = {
  icon: CompoundIconName
  label: string
  variant: NavMenuItemVariant
  onClick?: () => void

  'data-testid'?: string
  'data-status'?: 'active' | undefined
}

export function NavMenuItemInner({
  icon,
  label,
  variant,
  'data-testid': dataTestId,
  onClick,
  ...linkProps
}: NavMenuItemProps): JSX.Element {
  const largeScreen = useLargeScreen()

  const isActive = linkProps['data-status'] === 'active'

  const activeStyle = isActive && largeScreen
  const iconColor = variant === 'backoffice' ? colors.gray[700] : secondaryColor

  const buttonSx = getButtonSx(variant, activeStyle)

  const size: IconSize = variant === 'backoffice' ? '30' : '40'

  return (
    <Button
      component="a"
      onClick={onClick}
      sx={buttonSx}
      variant="text"
      data-testid={dataTestId}
      aria-label={label}
      {...linkProps}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: PIXELS_PER_ICON_SIZE[size],
          height: PIXELS_PER_ICON_SIZE[size],
        }}
      >
        <Icon icon={icon} color={iconColor} size={size} />
      </Box>

      <Text variant="body2Medium">{label}</Text>
    </Button>
  )
}

function getButtonSx(variant: NavMenuItemVariant, isActive: boolean): SxProps {
  const sx: SxProps = { ...navMenuItemSx }
  switch (variant) {
    case 'backoffice':
      return {
        ...sx,
        backgroundColor: isActive ? colors.blue[100] : colors.white,
      }
    case 'clientapp':
      return {
        ...sx,
        backgroundColor: isActive ? colors.blue[100] : colors.white,
        padding: spacing[50],
      }
  }
}

export const NavMenuItem = createLink(NavMenuItemInner)
